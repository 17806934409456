.body {
  margin-top: 250px;
  text-align: center;
}
.paystack_payment_button {
  display: inline-block;
  border-radius: 4px;
  background-color: #08A4DA;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 14px;
  width: 300px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.paystack_payment_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.paystack_payment_button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.paystack_payment_button:hover {
  box-shadow: 0 0px 0px 0 rgba(0,0,0,0.24), 0 1px 10px 0 rgba(0,0,0,0.19);
}

.paystack_payment_button:hover span {
  padding-right: 25px;
}

.paystack_payment_button:hover span:after {
  opacity: 1;
  right: 0;
}

#register, #login {
  width: 300px;
  border: 1px solid #d6d7da;
  padding: 0px 15px 15px 15px;
  border-radius: 5px;
  font-family: arial;
  line-height: 16px;
  color: #333333;
  font-size: 14px;
  background: #ffffff;
  margin: 100px auto;
}

form label, form input {
  display: block;
  //margin-bottom: 10px;
  width: 90%
}

form input {
  padding: 10px;
  border: solid 1px #BDC7D8;

}

.button {
  background-color: #00BFFF;
  border-color: #3ac162;
  font-weight: bold;
  padding: 12px 15px;
  color: #ffffff;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}

.sucessMsg {
  color: #6B8E23;
  margin-bottom: 10px;
}

.secure {

  padding-top: 55px;
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 380px !important;

}